import request from '@/common/request'
import { commonParams } from '@/api/config'
import { getToken } from '@/common/auth'

export function QueryInvoiceInfo() {
  const loginKey = getToken()
  return request({
    url: './query/QueryInvoiceInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function QueryInvoiceDetail(RecID) {
  const loginKey = getToken()
  return request({
    url: './query/QueryInvoiceDetail',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      RecID
    })
  })
}

export function QueryInvoice(pageIndex,
  pageSize,
  sortKey,
  sortDirect,
  filters,
  HoldID,
  hasChild) {
  const loginKey = getToken()
  return request({
    url: './query/QueryInvoice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      pageIndex,
      pageSize,
      sortKey,
      sortDirect,
      filters,
      HoldID,
      hasChild
    })
  })
}

export function QueryInvoiceOrder() {
  const loginKey = getToken()
  return request({
    url: './query/QueryInvoiceOrder',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey
    })
  })
}

export function ModifyInvoice(newItem) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyInvoice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem
    })
  })
}

export function ApplyInvoice(RecIDs, newItem) {
  const loginKey = getToken()
  return request({
    url: './add/ApplyInvoice',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      RecIDs,
      newItem
    })
  })
}

export function ModifyInvoiceInfo(invoiceType,
  invoiceName,
  identityID,
  bankName,
  bankNumber,
  companyAddress,
  companyPhone) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyInvoiceInfo',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      invoiceType,
      invoiceName,
      identityID,
      bankName,
      bankNumber,
      companyAddress,
      companyPhone
    })
  })
}

export function ModifyInvoiceAddress(
  RecID,
  PeopleName,
  PeopleAddress,
  PeopleZipCode,
  PeoplePhone,
  isDefault) {
  const loginKey = getToken()
  return request({
    url: './modify/ModifyInvoiceAddress',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      RecID,
      PeopleName,
      PeopleAddress,
      PeopleZipCode,
      PeoplePhone,
      isDefault
    })
  })
}

export function AddInvoiceAddress(newItem) {
  const loginKey = getToken()
  return request({
    url: './add/AddInvoiceAddress',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      newItem
    })
  })
}

export function DeleteInvoiceAddress(RecIDs) {
  const loginKey = getToken()
  return request({
    url: './delete/DeleteInvoiceAddress',
    method: 'post',
    data: Object.assign({}, commonParams, {
      loginKey,
      RecIDs
    })
  })
}
