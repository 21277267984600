import { render, staticRenderFns } from "./wallet-invoice.vue?vue&type=template&id=1a30c925&scoped=true&"
import script from "./wallet-invoice.vue?vue&type=script&lang=js&"
export * from "./wallet-invoice.vue?vue&type=script&lang=js&"
import style0 from "./wallet-invoice.vue?vue&type=style&index=0&id=1a30c925&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a30c925",
  null
  
)

export default component.exports