<template>
  <div class="v-wrapper tool--none">
    <div class="v-body">
      <el-tabs v-model="activeName">
        <el-tab-pane name="1" label="发票列表">
          <el-row class="v-tip">
            <p>温馨提示：</p>
            <p>1.您的发票申请将于15个工作日内完成审批及开具，发票开具完成后3个工作日寄出。收到发票包裹后，如有问题请及时拒收。</p>
            <p>2.单张发票金额不能超过100万，若您申请的开票金额超过100万，我们会在审核通过后拆分为多笔不超过100万的发票申请记录，经拆分的发票金额总和将等于您的申请开票金额。</p>
          </el-row>
          <el-row class="v-invoiceAmount">
            <span>
              可开票总金额：<span class="v-invoicecount">{{invoiceTotal}}</span> 元
            </span>
          </el-row>
          <div class="v-verticalsplit"></div>
          <div class="v-searchGroup" >

            <el-date-picker v-model="searchTimeRange" type="daterange" range-separator="至" start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            <div class="inTool">
              <el-input v-model="searchText" placeholder="请输入发票抬头" clearable style="width: 225px;margin-right:8px;">
              </el-input>
              <el-button icon="el-icon-search" type="primary" class="border" @click="queryHandle" plain>
                查询
              </el-button>
            </div>
            <el-button icon="el-icon-plus" type="primary" class="border imr-0" v-ripple plain
              @click="openInvoiceDetail">
              发票申请
            </el-button>
          </div>
          <el-table class="v-table" :data="tableData" border>
            <el-table-column label="申请开票时间" prop="CreateTime" width="150">
              <p slot-scope="{row}">{{miment(row.CreateTime).format('YYYY-MM-DD hh:mm')}}</p>
            </el-table-column>
            <el-table-column label="发票号" prop="InvoiceID" show-overflow-tooltip>
              <p slot-scope="{row}" @click="openInvoiceDetail(row)" class="i-link">{{row.InvoiceID||'--'}}</p>
            </el-table-column>
            <el-table-column label="发票抬头" prop="InvoiceName"></el-table-column>
            <el-table-column label="发票金额(元)" prop="InvoiceTotal"></el-table-column>
            <el-table-column label="发票内容" prop="InvoiceBody" show-overflow-tooltip></el-table-column>
            <el-table-column label="发票类型" prop="InvoiceType"></el-table-column>
            <el-table-column label="状态" prop="ProcessStatus">
              <p slot-scope="{row}">
                <el-tag size="mini" v-if="row.ProcessStatus==1">审核中</el-tag>
                <el-tag size="mini" v-if="row.ProcessStatus==2" type="warning">开票中</el-tag>
                <el-tag size="mini" v-if="row.ProcessStatus==3" type="success">已寄出</el-tag>
              </p>
            </el-table-column>
            <el-table-column label="快递单号" prop="ExpressID"></el-table-column>
            <el-table-column label="备注" prop="Remark"></el-table-column>
          </el-table>
          <el-pagination class="v-pager" :page-size="pageSize" :pager-count="11" :current-page.sync="pageIndex"
            layout="total, sizes, prev, pager, next, jumper" :total="pageTotalCount" @current-change="pageCurrentChange"
            @size-change="pageSizeChange" popper-class="custom">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane name="2" label="信息管理">
          <el-form ref="form" :rules="rules" :model="form" label-width="130px" size="small" class="invoiceForm"
            label-position="right" :inline-message="true">
            <el-form-item label="发票类型：">
              增值税普通发票
            </el-form-item>
            <el-form-item label="普票类型：">
              <el-radio v-model="radioType" label="个人普票">个人普票</el-radio>
              <el-radio v-model="radioType" label="企业类普票">企业类普票</el-radio>
            </el-form-item>
            <el-form-item label="发票抬头：" prop="InvoiceName">
              <el-input v-model="form.InvoiceName" :maxlength="55"></el-input>
            </el-form-item>
            <el-form-item label="纳税人识别号：" prop="IdentityID" v-if="radioType=='企业类普票'">
              <el-input v-model="form.IdentityID"></el-input>
            </el-form-item>
            <el-form-item label="开户银行名称：" v-if="radioType=='企业类普票'">
              <el-input v-model="form.BankName"></el-input>
            </el-form-item>
            <el-form-item label="开户账号：" v-if="radioType=='企业类普票'">
              <el-input v-model="form.BankNumber"></el-input>
            </el-form-item>
            <el-form-item label="地址：" v-if="radioType=='企业类普票'">
              <el-input v-model="form.CompanyAddress"></el-input>
            </el-form-item>
            <el-form-item label="电话：" v-if="radioType=='企业类普票'">
              <el-input v-model="form.CompanyPhone"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" class="customStyle border" plain @click="saveInfo">保存修改</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane name="3" label="寄送地址">
          <el-row class="v-tip">
            <p>已保存了{{addresses.length}}条地址，还能保存{{10-addresses.length}}条地址</p>
          </el-row>
          <ul class="addressee">
            <li class="ui-panel view-address" v-for="(item, index) in addresses" :key="index">
              <div class="title">{{item.PeopleName}}</div>
              <h3>{{item.PeoplePhone}}</h3>
              <p>{{item.PeopleAddress}} 收</p>
              <p class="default-label" v-if="item.IsDefault">【默认地址】</p>
              <div class="action">
                <span @click="deleteAddress(item)">删除</span>
                <span @click="openAddress(item)">修改</span>
              </div>
            </li>
            <li class="ui-panel add-address" @click="openAddress()" v-if="addresses.length < 10">
              <i class="el-icon-plus icon"></i>
              <span>新增地址信息</span>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>

      <el-dialog title="编辑地址" custom-class="v-dialog v-dialog--self v-dialog--mdpadding v-dialog--footer" width="825px"
        :visible.sync="dialogAddress" v-if="dialogAddressReal" @closed="dialogAddressReal = false">
        <div class="in">
          <el-form ref="addressForm" :model="addressForm" :rules="addressRules" label-position="right"
            label-width="120px" class="dialogForm">
            <el-col :span="12">
              <el-form-item label="收取人姓名：" prop="PeopleName">
                <el-input v-model="addressForm.PeopleName" :maxlength="28"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话：" prop="PeoplePhone">
                <el-input v-model="addressForm.PeoplePhone" :maxlength="28"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="收取人地址：" prop="PeopleAddress">
                <el-input v-model="addressForm.PeopleAddress" type="textarea" :maxlength="128">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="邮政编码：" prop="PeopleZipCode">
                <el-input v-model="addressForm.PeopleZipCode" :maxlength="10" style="width:41%;margin-right:10px;"></el-input><el-checkbox v-model="addressForm.IsDefault">设为默认</el-checkbox>
              </el-form-item>
            </el-col>
          </el-form>
        </div>
        <div slot="footer">
          <el-button class="customStyle" type="text" size="medium" @click="dialogAddress=false">取消</el-button>
          <el-button class="customStyle" type="primary" plain size="medium" v-ripple @click="validAddressAndSave"
            :loading="saveAddressLoading">确定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="发票详情" custom-class="v-dialog v-dialog--lg v-dialog--mdpadding v-dialog--footer"
        :visible.sync="dialogInvoiceDetail" v-if="dialogInvoiceDetailReal" @closed="dialogInvoiceDetailReal = false">
        <div v-loading="dialogInvoiceDetailLoading" :element-loading-text="tableLoadingText"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 1)">
          <p class="v-sub-title">开票信息</p>
          <el-form label-width="8em" label-position="right" class="mdForm" :model="invoiceDetail.item"
            style="margin-left:40px;" :disabled="!!invoiceDetail.item.InvoiceRecID" :rules="rules" ref="invoiceForm1">
            <el-form-item label="发票类型">增值税普通发票</el-form-item>
            <el-form-item label="普票类型">
              <el-radio v-model="radioType" label="个人普票">个人普票</el-radio>
              <el-radio v-model="radioType" label="企业类普票">企业类普票</el-radio>
            </el-form-item>
            <el-form-item label="发票抬头" prop="InvoiceName">
              <el-input v-model="invoiceDetail.item.InvoiceName" :maxlength="55"></el-input>
            </el-form-item>
            <el-form-item label="纳税人识别号：" prop="IdentityID" v-if="radioType=='企业类普票'">
              <el-input v-model="invoiceDetail.item.IdentityID"></el-input>
            </el-form-item>
            <el-form-item label="开户银行名称：" v-if="radioType=='企业类普票'">
              <el-input v-model="invoiceDetail.item.BankName"></el-input>
            </el-form-item>
            <el-form-item label="开户账号：" v-if="radioType=='企业类普票'">
              <el-input v-model="invoiceDetail.item.BankNumber"></el-input>
            </el-form-item>
            <el-form-item label="地址：" v-if="radioType=='企业类普票'">
              <el-input v-model="invoiceDetail.item.CompanyAddress"></el-input>
            </el-form-item>
            <el-form-item label="电话：" v-if="radioType=='企业类普票'">
              <el-input v-model="invoiceDetail.item.CompanyPhone"></el-input>
            </el-form-item>
          </el-form>

          <p class="v-sub-title">开票订单</p>
          <el-table :data="invoiceDetail.item.InvoiceRecID ? invoiceDetail.list : invoiceOrders" class="v-table"
            height="200px" ref="orderListTable">
            <el-table-column type="selection" v-if="!invoiceDetail.item.InvoiceRecID" :selectable="row => !row.IsApply"
              width="80px"></el-table-column>
            <el-table-column label="订单号" prop="OrderNO" width="240px">
              <p slot-scope="{row}">{{row.OrderNO}}<el-tag v-if="row.IsApply && !invoiceDetail.item.InvoiceRecID"
                  size="mini" style="margin-left: 4px;">已开票</el-tag>
              </p>
            </el-table-column>
            <el-table-column label="订单名称" prop="OrderName" show-overflow-tooltip></el-table-column>
            <el-table-column label="金额(元)" prop="InvoiceTotal" width="100px"></el-table-column>
            <el-table-column label="创建时间" prop="CreateTime" width="200px">
              <p slot-scope="{row}">{{miment(row.CreateTime).format()}}</p>
            </el-table-column>
          </el-table>


          <p class="v-sub-title">收件人地址</p>
          <ul class="addressee" v-if="!invoiceDetail.item.InvoiceRecID">
            <li class="ui-panel view-address" v-for="(item, index) in addresses" :key="index"
              :class="{'selected': item.RecID == invoiceDetail.item.AddressID}" @click="selectAddress(item)">
              <div class="title">{{item.PeopleName}}</div>
              <h3>{{item.PeoplePhone}}</h3>
              <p>{{item.PeopleAddress}} 收</p>
              <p class="default-label" v-if="item.IsDefault">【默认地址】</p>
              <div class="action">
                <span @click="deleteAddress(item)">删除</span>
                <span @click="openAddress(item)">修改</span>
              </div>
            </li>
            <li class="ui-panel add-address" @click="openAddress()" v-if="addresses.length < 10">
              <i class="el-icon-plus icon"></i>
              <span>新增地址信息</span>
            </li>
          </ul>
          <el-form label-width="8em" label-position="right" class="mdForm" style="margin-left:40px;" v-else>
            <el-row type="flex">
              <el-col :span="12">
                <el-form-item label="收取人姓名">{{invoiceDetail.item.PeopleName}}</el-form-item>
                <el-form-item label="收取人地址">{{invoiceDetail.item.PeopleAddress}}</el-form-item>
                <el-form-item label="联系电话：">{{invoiceDetail.item.PeoplePhone}}</el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="快递单号：" v-if="false">{{invoiceDetail.item.ExpressID}}</el-form-item>
                <el-form-item label="开票状态：">
                  <el-tag size="mini" v-if="invoiceDetail.item.ProcessStatus==1">审核中</el-tag>
                  <el-tag size="mini" v-if="invoiceDetail.item.ProcessStatus==2" type="warning">开票中</el-tag>
                  <el-tag size="mini" v-if="invoiceDetail.item.ProcessStatus==3" type="success">已寄出</el-tag>
                </el-form-item>
              </el-col>
            </el-row>

          </el-form>
          <p class="v-sub-title" v-if="invoiceDetail.item.InvoiceRecID">发票信息</p>
          <el-form label-width="8em" label-position="right" class="mdForm" style="margin-left:40px;"
            v-if="invoiceDetail.item.InvoiceRecID && !isAdmin">
            <el-form-item label="发票号">{{invoiceDetail.item.InvoiceID || '--'}}</el-form-item>
            <el-form-item label="发票内容">{{invoiceDetail.item.InvoiceBody || '--'}}</el-form-item>
            <el-form-item label="快递号">{{invoiceDetail.item.ExpressID || '--'}}</el-form-item>
          </el-form>
          <el-form label-width="8em" label-position="right" :model="invoiceDetail.item" class="mdForm"
            style="margin-left:40px;" v-if="invoiceDetail.item.InvoiceRecID && isAdmin" :rules="rules"
            ref="expressForm">
            <el-form-item label="发票号" prop="InvoiceID">
              <el-input v-model="invoiceDetail.item.InvoiceID" :maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="发票内容" prop="InvoiceBody">
              <el-input v-model="invoiceDetail.item.InvoiceBody" :maxlength="127"></el-input>
            </el-form-item>
            <el-form-item label="快递号" prop="ExpressID">
              <el-input v-model="invoiceDetail.item.ExpressID" :maxlength="30"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="Remark">
              <el-input v-model="invoiceDetail.item.Remark" :maxlength="127"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="customStyle" type="primary" plain size="medium" v-ripple @click="saveExpressInfo">保存信息
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" v-if="!invoiceDetail.item.InvoiceRecID">
          <el-button class="customStyle" type="text" size="medium" @click="dialogInvoiceDetail=false">取消</el-button>
          <el-button class="customStyle" type="primary" plain size="medium" v-ripple @click="applyInvoice"
            :loading="applyLoading" :disabled="dialogInvoiceDetailLoading">确定</el-button>
        </div>
        <div slot="footer" v-else>
          <el-button class="customStyle" type="text" size="medium" @click="dialogInvoiceDetail=false">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import mixin from '@/views/manage/mixin'
  import {
    mapGetters
  } from 'vuex'
  import {
    Filter,
    Op
  } from '@/api/config'
  import * as API from '@/api/invoice'
  import miment from 'miment'

  export default {
    name: 'walletInvoice',
    data() {
      return {
        miment,


        activeName: '1',

        // 可开票金额
        invoiceTotal: 0,

        // 是否管理员登录
        isAdmin: false,

        // 地址列表
        addresses: [],

        // 发票列表
        searchTimeRange: [],

        loading: false,

        options: [{
          label: '',
          value: '',
          Op: Op.Contains
        }],

        // 地址编辑
        dialogAddress: false,
        dialogAddressReal: false,
        addressForm: {},
        addressRules: {
          PeopleName: [{
            required: true,
            trigger: 'blur',
            message: '收取人姓名不能为空'
          }],
          PeopleAddress: [{
            required: true,
            trigger: 'blur',
            message: '街道地址不能为空'
          }],
          PeoplePhone: [{
            required: true,
            trigger: 'blur',
            message: '联系电话不能为空'
          }]
        },
        saveAddressLoading: false,

        // 发票抬头信息
        rules: {
          InvoiceName: [{
            required: true,
            trigger: 'blur',
            message: '发票抬头不能为空'
          }],
          IdentityID: [{
            required: true,
            trigger: 'blur',
            message: '纳税人识别号不能为空'
          }],
          InvoiceID: [{
            required: true,
            trigger: 'blur',
            message: '发票号不能为空'
          }],
          InvoiceBody: [{
            required: true,
            trigger: 'blur',
            message: '发票内容不能为空'
          }],
          ExpressID: [{
            required: true,
            trigger: 'blur',
            message: '快递单号不能为空'
          }]
        },
        form: {
          InvoiceType: '',
          InvoiceName: null, // 发票抬头
          IdentityID: null,
          BankName: null,
          BankNumber: null,
          CompanyAddress: null,
          CompanyPhone: null
        },
        radioType: '个人普票',
        saveInvoiceLoading: false,

        dialogInvoiceDetail: false,
        dialogInvoiceDetailReal: false,
        dialogInvoiceDetailLoading: false,

        // 可开票信息
        invoiceOrders: [],
        // 发票详情
        invoiceDetail: {
          item: {},
          list: []
        },
        applyLoading: false
      }
    },
    watch: {
      currentHoldId() {
        // if (this.$route.name == 'walletInvoice') {
        //   this.$router.push('/walletView')
        // }
      }
    },
    computed: {
      ...mapGetters([
        'currentHoldId',
        'loginInfo'
      ])
    },
    mixins: [mixin],
    methods: {
      queryHandle() {
        let holdId = this.loginInfo.HoldID
        let hasChild = holdId === 1
        this.loading = true
        let filters = []
        if (this.searchText) {
          filters.push(new Filter('InvoiceName', this.searchText, Op.Contains))
        }
        API.QueryInvoice(this.pageIndex, this.pageSize, this.sortKey, this.sortDirect, filters, holdId, hasChild).then(
          ret => {
            this.loading = false
            if (ret.data.errCode) {
              this.$alert(ret.data.errMsg)
              return
            }
            this.tableData = ret.data.list
          })
      },

      fetchPageData() {
        API.QueryInvoiceInfo().then(ret => {
          if (ret.data.errCode) {
            this.$alert(ret.data.errMsg)
            return
          }

          this.invoiceTotal = ret.data.data.InvoiceTotal // 开票金额
          this.form = ret.data.data.InvoiceInfo // 发票抬头
          this.addresses = ret.data.data.Addresses // 地址列表
          this.isAdmin = ret.data.data.IsAdmin // 是否管理员
          console.log(ret.data)
        })
      },

      fetchInvoiceOrders() {
        API.QueryInvoiceOrder().then(ret => {
          if (ret.data.errCode) {
            this.$alert(ret.data.errMsg)
            return
          }
          this.invoiceOrders = ret.data.list
        })
      },

      fetchInvoiceDetail(id) {
        this.dialogInvoiceDetailLoading = true
        API.QueryInvoiceDetail(id).then(ret => {
          this.dialogInvoiceDetailLoading = false
          if (ret.data.errCode) {
            this.$alert(ret.data.errMsg)
            return
          }
          this.invoiceDetail = ret.data
        })
      },

      openInvoiceDetail(row) {
        this.fetchInvoiceOrders()
        if (row && row.InvoiceRecID) {
          this.fetchInvoiceDetail(row.InvoiceRecID)
        } else {
          this.fetchInvoiceDetail(0)
        }

        this.$nextTick(() => {
          this.dialogInvoiceDetail = true
          this.dialogInvoiceDetailReal = true
        })
      },

      openAddress(row) {
        if (!row) {
          row = Object.assign({}, {
            RecID: 0,
            PeopleName: null,
            PeopleAddress: null,
            PeopleZipCode: null,
            PeoplePhone: null,
            isDefault: false
          })
        }
        this.addressForm = Object.assign({}, row)
        this.dialogAddress = true
        this.dialogAddressReal = true
      },

      deleteAddress(row) {
        this.$confirm('确定要删除该地址么', this.$t('common.warn'), {
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
          type: 'warning'
        }).then(() => {
          API.DeleteInvoiceAddress([row.RecID]).then(ret => {
            if (ret.data.errCode) {
              this.$alert(ret.data.errMsg)
              return
            }
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.fetchPageData()
          })
        })
      },

      validAddressAndSave() {
        this.$refs.addressForm.validate().then(this.saveAddress)
      },

      saveAddress() {
        this.saveAddressLoading = true
        if (this.addressForm.RecID) {
          // 修改
          API.ModifyInvoiceAddress(
              this.addressForm.RecID,
              this.addressForm.PeopleName,
              this.addressForm.PeopleAddress,
              this.addressForm.PeopleZipCode,
              this.addressForm.PeoplePhone,
              this.addressForm.IsDefault)
            .then(ret => {
              this.saveAddressLoading = false
              if (ret.data.errCode) {
                this.$alert(ret.data.errMsg)
                return
              }
              this.$message({
                message: this.$t('common.success'),
                type: 'success'
              })
              this.dialogAddress = false
              this.fetchPageData()
            })
        } else {
          // 新增
          API.AddInvoiceAddress(this.addressForm).then(ret => {
            this.saveAddressLoading = false
            if (ret.data.errCode) {
              this.$alert(ret.data.errMsg)
              return
            }
            this.$message({
              message: this.$t('common.success'),
              type: 'success'
            })
            this.dialogAddress = false
            this.fetchPageData()
          })
        }
      },

      saveInfo() {
        // 验证表单

        this.$refs.form.validate().then(() => {
          this.saveInvoiceLoading = true
          API.ModifyInvoiceInfo(
            '增值税普通发票',
            this.form.InvoiceName,
            this.form.IdentityID,
            this.form.BankName,
            this.form.BankNumber,
            this.form.CompanyAddress,
            this.form.CompanyPhone
          ).then(ret => {
            this.saveInvoiceLoading = false
            if (ret.data.errCode) {
              this.$alert(ret.data.errMsg)
              return
            }
            this.$message({
              message: this.$t('common.success'),
              type: 'success'
            })
          })
        })
      },

      applyInvoice() {
        const selected = this.$refs.orderListTable.selection.slice()
        if (selected.length === 0) {
          return this.$alert('没有选择开票订单')
        }
        let total = 0
        selected.forEach(k => total += k.InvoiceTotal)
        if (total === 0) {
          return this.$alert('开票金额不能为0')
        }
        if (!this.invoiceDetail.item.PeopleAddress || !this.invoiceDetail.item.PeoplePhone || !this.invoiceDetail.item
          .PeopleName) {
          return this.$alert('必须填写收件人地址')
        }
        this.$refs.invoiceForm1.validate().then(() => {
          this.applyLoading = true
          API.ApplyInvoice(selected.map(k => k.RecID), this.invoiceDetail.item).then(ret => {
            this.applyLoading = false
            if (ret.data.errCode) {
              this.$alert(ret.data.errMsg)
              return
            }
            this.$message({
              message: this.$t('common.success'),
              type: 'success'
            })
            this.dialogInvoiceDetail = false
          })
        })
      },

      saveExpressInfo() {
        this.$refs.expressForm.validate().then(() => {
          API.ModifyInvoice(this.invoiceDetail.item).then(ret => {
            if (ret.data.errCode) {
              return this.$alert(ret.data.errMsg)
            }
            this.$message({
              message: this.$t('common.success'),
              type: 'success'
            })
            this.queryHandle()
          })
        })
      },

      selectAddress: function (item) {
        this.invoiceDetail.item.PeopleAddress = item.PeopleAddress;
        this.invoiceDetail.item.PeoplePhone = item.PeoplePhone;
        this.invoiceDetail.item.PeopleName = item.PeopleName;
        this.invoiceDetail.item.AddressID = item.RecID;
      }
    },
    created() {
      // 设置时间为本年份
      const now = miment()
      let start = miment(miment().get('YYYY'), 0, 1)
      let end = now
      this.searchTimeRange = [start, end]

      // 获取首页数据
      this.fetchPageData()
      // 获取发票详情
      this.queryHandle()
    }
  }

</script>

<style lang="scss" scoped>
  .v-wrapper {
    display: flex;
    align-items: stretch;
    padding: 8px;

    .v-nav {
      width: 250px;
      flex-shrink: 0;
      overflow-y: auto;
      position: relative;
    }

    .v-body {
      width: 0;
      flex-grow: 1;
    }
  }

  //@import '../../styles/variables-simple';
  $--color-primary: #1890ff;

  .el-tabs {

    & ::v-deep {
      .el-tabs__nav {
        border: none;
        position: relative;
        right: -20px;
      }

      .el-tabs__item {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.54);
        padding-left: 20px !important;
        padding-right: 20px !important;
        border-left: none;
        position: relative;
        top: 1px;
        z-index: 1;

        &.is-active {
          color: #1890ff;
          border: 1px solid #1890ff;
          border-top-right-radius: 7px;
          border-top-left-radius: 7px;
          border-bottom: 1px solid #fff;
        }
      }

      .el-tabs__active-bar {
        display: none;
      }

      .el-tabs__nav-wrap::after {
        height: 1px;
      }
    }
  }

  $commonMargin: 24px;

  .v-tip {
    margin: 10px 22px;
    padding: 10px 19px;
    background-color: #e7f4ff;
    color: #1890ff;
    font-size: 12px;
    line-height: 22px;
    border: 1px dashed #1890ff;
    border-radius: 10px;
  }

  .v-invoiceAmount {
    margin: 10px 22px;
    padding: 20px 30px;
    font-size: 18px;
    font-weight: 500;
    color: #4d5b73;
    background-color: #f3f4f8;
    border-radius: 5px;


    .v-invoicecount {
      color: green;
    }
  }

  .v-searchGroup {
    // margin: $commonMargin /2;
    margin: 10px 22px;
    display: flex;
    .el-date-editor{
      border-radius: 20px;
    }
    .inTool{
      flex-grow: 1;
      text-align: right;
      & ::v-deep .el-input__inner{
        border-radius: 20px;
      }
    }
    .el-button{
      border-radius: 20px;
      margin-left: 15px;
    }
  }

  .v-table {
    width: calc(100% - #{$commonMargin*2}) !important;
    margin: $commonMargin / 2 22px!important ;
  }

  .el-date-editor--daterange {
    width: 275px;

    & ::v-deep .el-range-separator {
      width: 3em;
    }
  }

  ul.addressee {
    list-style: none;
    padding: 0 $commonMargin / 2;
  }

  li.ui-panel {
    width: 290px;
    height: 170px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
    cursor: pointer;
    vertical-align: top;
    border: 1px solid #e2e2e2;

    &.selected {
      outline: 2px solid $--color-primary;
    }

    &.unselected {
      outline: 2px solid #DCDFE6;
    }

    &.add-address {
      background-color: #fff;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;

      .icon {
        font-size: 32px;
        font-weight: 700;
        color: #CCCCCC;
        margin-bottom: 12px;
      }

      span {
        font-size: 12px;
        color: #666;
      }
    }

    &.view-address {
      padding: 10px 20px;
      background-color: #fff;
      border-radius: 5px;
      position: relative;
      font-size: 16px;

      .title {
        line-height: 30px;
      }

      h3 {
        line-height: 24px;
        font-size: 16px;
      }

      p {
        width: 80%;
        min-height: 60px;
        line-height: 22px;
        font-size: 14px;
      }

      .action {
        position: absolute;
        right: 20px;
        bottom: 20px;
        z-index: 1;
        display: flex;
        color: #1890ff;
        font-size: 14px;

        span {
          padding-right: 10px;
        }
      }
    }
  }

  .dialogForm {
    // width: 375px;
    margin: 0 auto 0;
  }
  .v-dialog--self{
    .el-dialog__body{
      height: 330px !important;
    }
  }

  .invoiceForm {
    margin: $commonMargin;

    .el-input {
      width: 215px;
    }
  }

  .mdForm {
    .el-input {
      width: 215px;
    }
  }

  .in {
    height: auto;
    overflow: hidden;
    border: 1px solid #f9f9f9;
    padding: 20px 30px;
    border-radius: 10px;
    margin-top: 20px;
  }

  .v-sub-title {
    color: rgba(0, 0, 0, .54);
    border-left: 1px solid rgba(0, 0, 0, .54);
    padding-left: 12px;
    margin-bottom: 12px;
  }

</style>
